import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class UserService {
  sendMintCount(data) {
    return axios.post(API_URL + 'sendMintCount', {
      mint_count: data.mintCount,
    })
      .then(response => {
        return response.data
      })
  }

  getAllNFT() {
    return axios.get(API_URL + 'admin/nft/all', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getCreator() {
    return axios.get(API_URL + 'client/nft/creator', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  updateAvatar(data) {
    return axios.post(API_URL + 'client/profile/updateAvatar', data, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  updateUser(data) {
    return axios.post(API_URL + 'client/profile/update', data, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getClientCatalog() {
    return axios.get(API_URL + 'client/nft/catalog', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getMyCatalog(owner) {
    return axios.get(API_URL + `client/catalog/my/${owner}`, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  setAddress(data) {
    return axios.post(API_URL + 'client/nft/setAddress', data, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  mint(data) {
    return axios.post(API_URL + 'client/nft/mint', data, { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getAdminCatalog() {
    return axios.get(API_URL + 'admin/catalog', { headers: authHeader() })
      .then(response => {
        return response
      })
  }

  getUsers() {
    return axios.get(API_URL + 'admin/users', { headers: authHeader() })
      .then(response => {
        return response
      })
  }
}

export default new UserService()
