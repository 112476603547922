import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from 'axios'
import VueAxios from 'vue-axios'
import vClickOutside from 'click-outside-vue3'
import SolanaWallets from './plugins/solana-wallets-vue'
import './plugins/solana-wallets-vue/styles.css'
import {
  PhantomWalletAdapter,
} from '@solana/wallet-adapter-phantom'
import { initWallet } from './plugins/solana-wallets-vue';

const walletOptions = {
  wallets: [
    new PhantomWalletAdapter()
  ],
  autoConnect: true,
}

initWallet(walletOptions);

createApp(App)
  .use(store)
  .use(router)
  .use(VueAxios, axios)
  .use(vClickOutside)
  .use(SolanaWallets, walletOptions)
  .mount('#app')
