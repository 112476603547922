<template>
  <div class="running-line">
    <div class="running-line__text-block">
      <div class="running-line__text">Be the meta champion</div>
    </div>
    <div class="running-line__text-block">
      <div class="running-line__text">Be the meta champion</div>
    </div>
    <div class="running-line__text-block">
      <div class="running-line__text">Be the meta champion</div>
    </div>
    <div class="running-line__text-block">
      <div class="running-line__text">Be the meta champion</div>
    </div>
    <div class="running-line__text-block">
      <div class="running-line__text">Be the meta champion</div>
    </div>
    <div class="running-line__text-block">
      <div class="running-line__text">Be the meta champion</div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";

export default defineComponent({
  name: "RunningLine",
});
</script>

<style lang="scss" scoped>
.running-line {
  height: 80px;
  background: linear-gradient(92.57deg, #a8a3d6 -5.02%, #8d8a8a 72.2%);
  box-shadow: inset 0px 5px 8px rgba(0, 0, 0, 0.25);
  display: flex;
  align-items: center;
  width: 100%;
  overflow: hidden;

  &__text-block {
    display: flex;
    text-align: center;
    color: #fff;
    text-transform: uppercase;
    animation: text 20s infinite linear;
  }

  &__text {
    margin: 0 30px;
    font-style: italic;
    font-weight: 800;
    font-size: 24px;
    letter-spacing: 0.03em;
    text-transform: uppercase;
    color: #ffffff;
    width: 350px;
  }
}

@keyframes text {
  0% {
    transform: translate(0, 0);
  }

  100% {
    transform: translate(-100%, 0);
  }
}
@media (max-width: $break-xlg) {
  .running-line {
    height: 48px;

    &__text {
      font-size: 16px;
      width: 250px;
    }
  }
}
@media (max-width: $break-md) {
  .running-line {
  }
}

@media (max-width: $break-sm) {
.running-line {

}
}
</style>
