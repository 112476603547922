import axios from 'axios'
import authHeader from './auth-header'

const API_URL = process.env.VUE_APP_URL

class AuthService {
  login (user: any) {
    return axios
      .post(API_URL + 'admin/auth/login', {
        email: user.email,
        password: user.password
      })
      .then(response => {
        if (response.data.access_token) {
          localStorage.setItem('user', JSON.stringify(response.data.access_token))
        }

        return response.data
      })
  }

  loginUser (user: any) {
    return axios
      .post(API_URL + 'client/auth/login', {
        public_key: user.public_key,
        email: user.email,
        name: user.name,
        news_subscription: user.news_subscription
      })
      .then(response => {
        return response.data
      })
  }

  verificationEmail (data: any) {
    return axios
      .post(API_URL + 'client/verification/send', {
        email: data.email,
      }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  verifyEmail (data: any) {
    return axios
      .post(API_URL + 'client/verification/verify', {
        code: data.code,
        token: data.token,
      }, { headers: authHeader() })
      .then(response => {
        return response.data
      })
  }

  logout () {
    return axios
      .post(API_URL + 'admin/auth/logout', {
      }, { headers: authHeader() })
  }

  checkExistence (data: any) {
    return axios
      .post(API_URL + 'client/auth/checkExistence', {
        public_key: data
      }, { headers: authHeader() })
  }

  emailVerify (data: any) {
    return axios
      .post(API_URL + 'auth/email/verify', {
        token: data
      }, { headers: authHeader() })
  }

  passwordRecoveryEmail (data: any) {
    return axios
      .post(API_URL + 'auth/password/recovery-email', {
        email: data.email
      }, { headers: authHeader() })
  }

  newPassword (data: any) {
    return axios
      .post(API_URL + 'auth/password/new', {
        password: data.password,
        password_confirmation: data.passwordRecovery,
        token: data.token
      }, { headers: authHeader() })
  }
}

export default new AuthService()
