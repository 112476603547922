<template>
  <router-view v-slot="{ Component }">
    <transition name="fade" mode="out-in">
        <component :is="Component"></component>
    </transition>
</router-view>
</template>

<style lang="scss">
#app {
  font-family: 'Montserrat', sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #000000;
}

.carousel__prev .carousel__icon, .carousel__next .carousel__icon {
  display: none!important;
}

.carousel__prev {
  background: transparent!important;
  background-image: url("./assets/image/arrow-prev.png")!important;
  background-position: center;
  width: 65px!important;
  height: 65px!important;

  &:hover {
    cursor: pointer;
  }
}

.carousel__next {
  background: transparent!important;
  background-image: url("./assets/image/arrow-next.png")!important;
  background-position: center;
  width: 65px!important;
  height: 65px!important;

  &:hover {
    cursor: pointer;
  }
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease;
}


.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.form-group {
  position: relative;
}
.error-feedback {
  color: #FF444F;
  font-size: 12px;
  position: absolute;
  bottom: 5px;;
  left: 15px;
}

.field {
    font-family: $fontFamily;
    width: 300px;
    height: 48px;
    padding: 0 0 0 15px;
    border: 1px solid #000000;
    border-radius: 5px;
    font-size: 16px;

    &::placeholder {
      color: #b8b8b8;
    }
  }

@media (max-width: $break-sm) {
}
</style>
