<template>
  <div class="collect">
    <div class="collect__title">Collect and scout</div>
    <div class="collect__second-title">
      Each player has a unique skill set. Collect your ultimate team or join
      others to make a dream team and share rewards.
    </div>
    <div class="collect__slider-wrap">
      <Carousel
        :settings="settings"
        :breakpoints="breakpoints"
        :wrapAround="true"
        :autoplay="3000000"
      >
        <Slide v-for="slide in slidesCollect" :key="slide">
          <CardHome :imgCatalog="slide.image" :name="slide.name" :id="slide.id" :favorite="favoriteSlide" />
        </Slide>

        <template #addons>
          <Navigation />
        </template>
      </Carousel>
    </div>
    <MainButton class="collect__button">
      {{ this.data.joinUs }}
    </MainButton>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Pagination, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";
import MainButton from "@/components/atoms/buttons/MainButton.vue";
import CardHome from "@/components/molecules/CardHome.vue";


export default defineComponent({
  name: "Collect",
  data() {
    return {
      data: {
        joinUs: "Join Discord",
      },
      settings: {
        itemsToShow: 4,
        snapAlign: "start",
      },
      breakpoints: {
        320: {
          itemsToShow: 1,
          snapAlign: "center",
        },
        560: {
          itemsToShow: 3,
          snapAlign: "start",
        },
        1024: {
          itemsToShow: 4,
          snapAlign: "start",
        },
        1700: {
          itemsToShow: 5,
          snapAlign: "start",
        },
      },
    };
  },
  props: {
    slidesCollect: {
      type: Array,
      default: [],
    },
    favoriteSlide: {
      type: Function
    }
  },
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
    MainButton,
    CardHome,
  },
});
</script>

<style lang="scss" scoped>
@import url("https://fonts.googleapis.com/css2?family=Goldman:wght@700&display=swap");
.collect {
  &__title {
    @include MainSectiontitle;
    text-align: center;
  }

  &__second-title {
    font-size: 20px;
    line-height: 24px;
    padding: 15px 0 40px 0;
    max-width: 900px;
    margin: auto;
  }

  &__slider-wrap {
    padding: 0 8% 0 7.1%;
  }

  &__button {
    width: 344px;
    margin: 30px auto;
  }
}
::v-deep(.carousel__prev) {
  transform: translate(-75%, -50%);
}

::v-deep(.carousel__next) {
  transform: translate(85%, -50%);
  right: 2px;
}

@media (max-width: $break-xlg) {
  .collect {

     &__title {
      font-size: 40px;
    }

    &__second-title {
      font-size: 16px;
      max-width: 530px;
    }

    ::v-deep(.carousel__item) {
      width: 225px;
    }
  }
}
@media (max-width: $break-md) {
  .collect {

    &__title {
      font-size: 40px;
    }

    &__second-title {
      font-size: 16px;
      max-width: 530px;
    }

    ::v-deep(.carousel__item) {
      width: 225px;
    }
  }
}

@media (max-width: $break-sm) {
  .collect {
    &__title {
      font-size: 30px;
      line-height: 30px;
      width: 250px;
      text-align: center;
      margin: auto;
    }

    &__second-title {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      margin: auto;
      padding: 10px 0 0 0;
    }

    &__button {
      width: 260px;
      margin: 30px auto;
    }

    ::v-deep(.carousel__item) {
      transform: scale(0.9) !important;
    }

    ::v-deep(.carousel__next) {
      transform: translate(50%, -50%);
    }

    ::v-deep(.carousel__prev) {
      transform: translate(-40%, -50%);
    }
  }
}
</style>
