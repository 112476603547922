<template>
  <div class="plan-train">
    <div>
      <div class="plan-train__title">
        Plan, train <br />
        and win
      </div>
      <div class="plan-train__text">
        Choose the tactic, train the <br />
        team and go for a championship
      </div>
      <MainButton disabled class="plan-train__button">
        Try tactical sim
      </MainButton>
    </div>
    <!-- <img :src="require('../../../assets/image/plan-train.png')" alt="" /> -->
    <PlanTrainSvg />
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MainButton from "@/components/atoms/buttons/MainButton.vue";
import PlanTrainSvg from "@/assets/svg/plan-train.svg?inline";

export default defineComponent({
  name: "PlanTrain",
  components: {
    MainButton,
    PlanTrainSvg,
  },
});
</script>

<style lang="scss" scoped>
.plan-train {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 100px 5%;

  img,
  svg {
    width: 700px;
  }

  &__title {
    @include MainSectiontitle;
    text-align: left;
  }

  &__text {
    font-size: 20px;
    line-height: 24px;
    text-align: left;
    padding: 15px 0 30px 0;
  }

  &__button {
    width: 300px;
  }
}
@media (max-width: $break-xlg) {
  .plan-train {
    padding: 50px 5%;

    &__title {
      font-size: 40px;
      line-height: 40px;
      width: 300px;
      margin-right: 30px;
    }

    &__text {
      font-size: 16px;
      padding: 5px 0 10px 0;
    }

    &__button {
      width: 260px;
    }
  }
}

@media (max-width: $break-md) {
  .plan-train {
    padding: 50px 5%;

    &__title {
      font-size: 40px;
      line-height: 40px;
      width: 300px;
      margin-right: 30px;
    }

    &__text {
      font-size: 16px;
      padding: 5px 0 10px 0;
    }

    &__button {
      width: 260px;
    }
  }
}

@media (max-width: $break-sm) {
  .plan-train {
    flex-direction: column;
    padding: 20px 5%;

    img,
    svg {
      width: 100%;
    }

    &__title {
      font-size: 30px;
      line-height: 30px;
      width: 250px;
      text-align: center;
      margin: auto;
    }

    &__text {
      font-size: 14px;
      line-height: 18px;
      text-align: center;
      padding: 10px 0 20px 0;
    }

    &__button {
      width: 260px;
    }

    svg {
      padding-top: 50px;
    }
  }
}
</style>
