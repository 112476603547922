import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import Home from '../views/Home.vue'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    name: 'Home',
    component: Home
  },
  {
    path: '/registration-phantom',
    name: 'RegistrationPhantom',
    component: () => import('../views/RegistrationPhantom.vue')
  },
  {
    path: '/admin',
    name: 'AdminLogin',
    component: () => import('../views/Admin.vue')
  },
  {
    path: '/create-user',
    name: 'CreateUser',
    component: () => import('../views/CreateUser.vue')
  },
  {
    path: '/email-confirmed',
    name: 'EmailConfirmed',
    component: () => import('../views/EmailConfirmed.vue')
  },
  {
    path: '/email-verify',
    name: 'EmailVerify',
    component: () => import('../views/EmailVerify.vue')
  },
  {
    path: '/confirm',
    name: 'Confirm',
    component: () => import('../views/Confirm.vue')
  },
  {
    path: '/mint',
    name: 'MintHome',
    component: () => import('../views/Mint.vue')
  },
  {
    path: '/password-forgot',
    name: 'PasswordForgot',
    component: () => import('../views/PasswordForgot.vue')
  },
  {
    path: '/password-recovery',
    name: 'PasswordRecovery',
    component: () => import('../views/PasswordRecovery.vue')
  },
  {
    path: '/dashboard/my-collection',
    name: 'MyCollection',
    component: () => import('../views/dashboard/MyCollection.vue')
  },
  {
    path: '/dashboard/collection-details/:id',
    name: 'CollectionDetails',
    component: () => import('../views/dashboard/CollectionDetails/CollectionDetails.vue')
  },
  {
    path: '/dashboard/catalog',
    name: 'Catalog',
    component: () => import('../views/dashboard/Catalog.vue')
  },
  {
    path: '/dashboard/catalog/details/:id',
    name: 'CatalogDetails',
    component: () => import('../views/dashboard/CatalogDetails/_.vue')
  },
  {
    path: '/dashboard/mint',
    name: 'Mint',
    component: () => import('../views/dashboard/Mint.vue')
  },
  {
    path: '/dashboard/mint/:count',
    name: 'SeccessfulMint',
    component: () => import('../views/dashboard/SeccessfulMint/_.vue')
  },
  {
    path: '/dashboard/profile',
    name: 'DashboardProfile',
    component: () => import('../views/dashboard/Profile.vue')
  },
  {
    path: '/dashboard/my-mht',
    name: 'DashboardMyMHT',
    component: () => import('../views/dashboard/MyMHT.vue')
  },
  {
    path: '/dashboard/get-mht',
    name: 'DashboardGetMHT',
    component: () => import('../views/dashboard/GetMHT.vue')
  },
  {
    path: '/dashboard/my-favorite',
    name: 'DashboardMyFavorite',
    component: () => import('../views/dashboard/MyFavorite.vue')
  },
  {
    path: '/admin/catalog',
    name: 'AdminCatalog',
    component: () => import('../views/admin/Catalog.vue')
  },
  {
    path: '/admin/all-pics',
    name: 'AllPics',
    component: () => import('../views/admin/AllPics.vue')
  },
  {
    path: '/admin/get-mht',
    name: 'AdminGetMHT',
    component: () => import('../views/admin/GetMHT.vue')
  },
  {
    path: '/admin/blog',
    name: 'Blog',
    component: () => import('../views/admin/Blog.vue')
  },
  {
    path: '/admin/create-invite-mht',
    name: 'CreateNewInviteMHT',
    component: () => import('../views/admin/CreateNewInviteMHT.vue')
  },
  {
    path: '/admin/users',
    name: 'Users',
    component: () => import('../views/admin/Users.vue')
  },
  {
    path: '/terms-conditions',
    name: 'TermsConditions',
    component: () => import('../views/service/TermsConditions.vue')
  },
  {
    path: '/privacy-policy',
    name: 'PrivacyPolicy',
    component: () => import('../views/service/PrivacyPolicy.vue')
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  const publicPages = ['/', '/admin', '/dashboard/collection-details', '/dashboard/my-mht', '/dashboard/my-favorite', '/dashboard/get-mht', '/dashboard/catalog', '/dashboard/my-collection', '/dashboard/catalog', 'email-confirmed', '/create-user', '/password-recovery', '/confirm', '/mint', '/registration-phantom', '/dashboard/mint']
  const authRequired = !publicPages.includes(to.path)
  const loggedIn = localStorage.getItem('user')
  if (authRequired && !loggedIn) {
    next('/')
  } else {
    next()
  }
})

export default router
