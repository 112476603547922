import AuthService from '@/services/auth.service'

const user: any = JSON.parse(localStorage.getItem('user') || '{}')
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const auth = {
  namespaced: true,
  state: initialState,
  actions: {
    login ({ commit }: any, user: any) {
      return AuthService.login(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    loginUser ({ commit }: any, user: any) {
      return AuthService.loginUser(user).then(
        user => {
          commit('loginSuccess', user)
          return Promise.resolve(user)
        },
        error => {
          commit('loginFailure')
          return Promise.reject(error)
        }
      )
    },
    verificationEmail ({ commit }: any, data: any) {
      return AuthService.verificationEmail(data).then(
        data => {
          return Promise.resolve(data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    verifyEmail ({ commit }: any, data: any) {
      return AuthService.verifyEmail(data).then(
        data => {
          return Promise.resolve(data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    logout ({ commit }: any) {
      AuthService.logout()
      commit('logout')
    },
    checkExistence ({ commit }: any, data: any) {
      return AuthService.checkExistence(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
    emailVerify ({ commit }: any, data: any) {
      return AuthService.emailVerify(data).then(
        response => {
          commit('emailVerifySuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('emailVerifyFailure')
          return Promise.reject(error)
        }
      )
    },
    passwordRecoveryEmail ({ commit }: any, data: any) {
      return AuthService.passwordRecoveryEmail(data).then(
        response => {
          commit('passwordRecoveryEmailSuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('passwordRecoveryEmailFailure')
          return Promise.reject(error)
        }
      )
    },
    newPassword ({ commit }: any, data: any) {
      return AuthService.newPassword(data).then(
        response => {
          commit('newPasswordSuccess')
          return Promise.resolve(response.data)
        },
        error => {
          commit('newPasswordFailure')
          return Promise.reject(error)
        }
      )
    },
  },
  mutations: {
    loginSuccess (state: any, user: any) {
      state.status.loggedIn = true
      state.user = user
    },
    loginFailure (state: any) {
      state.status.loggedIn = false
      state.user = null
    },
    logout (state: any) {
      state.status.loggedIn = false
      state.user = null
    },
  }
}
