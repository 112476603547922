import UserService from '@/services/user.service'

const user: any = JSON.parse(localStorage.getItem('user') || '{}')
const initialState = user
  ? { status: { loggedIn: true }, user }
  : { status: { loggedIn: false }, user: null }

export const getNFTs = {
  namespaced: true,
  state: initialState,
  actions: {
    sendMintCount ({ commit }: any, data: any) {
      return UserService.sendMintCount(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },

    getAllNFT ({ commit }: any) {
      return UserService.getAllNFT().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },

    getClientCatalog ({ commit }: any) {
      return UserService.getClientCatalog().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },

    updateAvatar ({ commit }: any, data: File) {
      return UserService.updateAvatar(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },

    updateUser ({ commit }: any, data: any) {
      return UserService.updateUser(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },

    setAddress ({ commit }: any, data: any) {
      return UserService.setAddress(data).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },

    mint ({ commit }: any, data: any) {
      return UserService.mint(data).then(
        response => {
          return Promise.resolve(response)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },

    getCreator ({ commit }: any) {
      return UserService.getCreator().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },

    getMyCatalog ({ commit }: any, owner: any) {
      return UserService.getMyCatalog(owner).then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },

    getAdminCatalog ({ commit }: any) {
      return UserService.getAdminCatalog().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },

    getUsers ({ commit }: any) {
      return UserService.getUsers().then(
        response => {
          return Promise.resolve(response.data)
        },
        error => {
          return Promise.reject(error)
        }
      )
    },
  },
  mutations: {
    resend (state: any) {
      state.status.loggedIn = false
    }
  }
}
