<template>
  <div class="more-about-nft">
    <div class="more-about-nft__title">More about NFT</div>
    <div class="more-about-nft__posts">
      <div class="more-about-nft__post">
        <img
          :src="require('../../../assets/image/more-about-nft.png')"
          alt=""
          class="more-about-nft__img"
        />
        <div>
          <div class="more-about-nft__post-title">Article title</div>
          <div class="more-about-nft__post-text">
            Sed ut perspiciatis, unde omnis iste natus error doloremque
            laudantiumn eaque ipsarspiciatis, unde omnis iste natus error
            doloremque laudantiumn eaque ipsa
          </div>
        </div>
      </div>
      <div class="more-about-nft__post">
        <img
          :src="require('../../../assets/image/more-about-nft.png')"
          alt=""
          class="more-about-nft__img"
        />
        <div>
          <div class="more-about-nft__post-title">Article title</div>
          <div class="more-about-nft__post-text">
            Sed ut perspiciatis, unde omnis iste natus error doloremque
            laudantiumn eaque ipsarspiciatis, unde omnis iste natus error
            doloremque laudantiumn eaque ipsa
          </div>
        </div>
      </div>
      <div class="more-about-nft__post">
        <img
          :src="require('../../../assets/image/more-about-nft.png')"
          alt=""
          class="more-about-nft__img"
        />
        <div>
          <div class="more-about-nft__post-title">Article title</div>
          <div class="more-about-nft__post-text">
            Sed ut perspiciatis, unde omnis iste natus error doloremque
            laudantiumn eaque ipsarspiciatis, unde omnis iste natus error
            doloremque laudantiumn eaque ipsa
          </div>
        </div>
      </div>
    </div>
    <MainButton class="more-about-nft__button"> More </MainButton>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import MainButton from "@/components/atoms/buttons/MainButton.vue";

export default defineComponent({
  name: "MoreAboutNFT",
  components: {
    MainButton,
  },
});
</script>

<style lang="scss" scoped>
.more-about-nft {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 150px 5% 0 5%;

  &__title {
    @include MainSectiontitle;
    text-align: left;
    padding-bottom: 60px;
  }

  &__button {
    width: 300px;
    margin: 60px auto 0 auto;
  }

  &__posts {
    display: flex;
    justify-content: space-between;
  }

  &__post {
    width: 32.5%;
    text-align: left;
    border: 3px solid #311ffe;
    box-shadow: 0px 4px 15px rgba(0, 0, 0, 0.15);
    border-radius: 8px;
    padding: 20px;
    background: #ffffff;
  }

  &__post-title {
    font-weight: 700;
    font-size: 24px;
    padding: 25px 0 10px 0;
  }

  &__post-text {
    font-size: 16px;
    line-height: 20px;
  }

  &__img {
    width: 100%;
    height: auto;
  }
}
@media (max-width: $break-xlg) {
  .more-about-nft {
    padding: 50px 5% 0 5%;

    &__button {
      width: 260px;
      margin: 30px auto 50px auto;
    }

    &__title {
      font-size: 44px;
      padding-bottom: 20px;
    }

    &__posts {
      flex-direction: column;
    }

    &__post {
      display: flex;
      width: 100%;
      margin-bottom: 10px;

      img {
        padding-right: 25px;
      }
    }
  }
}
@media (max-width: $break-md) {
  .more-about-nft {
    padding: 50px 5% 0 5%;

    &__button {
      width: 260px;
      margin: 30px auto 50px auto;
    }

    &__title {
      font-size: 44px;
      padding-bottom: 20px;
    }

    &__posts {
      flex-direction: column;
    }

    &__post {
      display: flex;
      width: 100%;
      margin-bottom: 10px;
    }
  }
}

@media (max-width: $break-sm) {
  .more-about-nft {
    padding: 30px 5% 0 5%;
    &__title {
      font-size: 30px;
      text-align: center;
      padding-bottom: 20px;
    }
    &__posts {
      flex-direction: column;
    }

    &__post {
      width: 100%;
      display: flex;
      flex-direction: column;
      margin-bottom: 20px;
    }

    &__button {
      width: 260px;
      margin: 10px auto 0 auto;
    }
  }
}
</style>
