<template>
  <div class="info-block">
    <div class="info-block__block">
      <div class="info-block__svg">
        <Info1 class="info-block__svg-desctop" />
        <Info1Phone class="info-block__svg-mob" />
      </div>
      <div class="info-block__text">
        <div class="info-block__title">Unique players</div>
        <div>
          Duis aute irure dolor in reprehenderit in volu ptate velit esse
        </div>
      </div>
    </div>
    <div class="info-block__block">
      <div class="info-block__svg">
        <Info2 class="info-block__svg-desctop" />
        <Info2Phone class="info-block__svg-mob" />
      </div>
      <div class="info-block__text">
        <div class="info-block__title">Training and tactics</div>
        <div>
          Duis aute irure dolor in reprehenderit in volu ptate velit esse
        </div>
      </div>
    </div>
    <div class="info-block__block">
      <div class="info-block__svg">
        <Info3 class="info-block__svg-desctop" />
        <Info3Phone class="info-block__svg-mob" />
      </div>
      <div class="info-block__text">
        <div class="info-block__title">Weekly championships</div>
        <div>
          Duis aute irure dolor in reprehenderit in volu ptate velit esse
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import Info1 from "@/assets/svg/main/info1.svg?inline";
import Info1Phone from "@/assets/svg/adaptive/info1.svg?inline";
import Info2 from "@/assets/svg/main/info2.svg?inline";
import Info2Phone from "@/assets/svg/adaptive/info2.svg?inline";
import Info3 from "@/assets/svg/main/info3.svg?inline";
import Info3Phone from "@/assets/svg/adaptive/info3.svg?inline";

export default defineComponent({
  name: "InfoBlock",
  components: {
    Info1,
    Info2,
    Info3,
    Info1Phone,
    Info2Phone,
    Info3Phone,
  },
});
</script>

<style lang="scss" scoped>
.info-block {
  display: flex;
  justify-content: space-between;
  padding: 120px 5% 130px 5%;

  &__block {
    display: flex;
  }

  &__text {
    padding: 10px 0 10px 15px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-start;
    text-align: start;
    font-size: 16px;
    line-height: 18px;
    max-width: 220px;
  }

  &__svg-mob {
    display: none;
  }

  &__title {
    font-style: italic;
    font-weight: 800;
    font-size: 22px;
    line-height: 26px;
    max-width: 155px;
  }

  &__svg {
    width: 141px;
    height: 141px;
    background: linear-gradient(92.57deg, #311ffe -5.02%, #0b0086 72.2%);
    box-shadow: inset 0px 5px 8px rgb(0 0 0 / 35%);
    display: flex;
    align-items: center;
    justify-content: center;
    border-radius: 100px;
  }
}

@media (max-width: $break-xlg) {
  .info-block {
    padding: 60px 5% 60px 5%;
    flex-direction: column;
    align-items: center;

    &__title {
      max-width: 100%;
    }

    &__block {
      padding: 10px 0;
    }

    &__text {
      max-width: 400px;
      justify-content: space-around;
      padding-left: 30px;
    }

    &__svg-mob {
      display: block;
    }
    &__svg-desctop {
      display: none;
    }

    &__svg {
      width: 100px;
      height: 100px;
    }
  }
}
@media (max-width: $break-md) {
  .info-block {
    padding: 60px 5% 60px 5%;
    flex-direction: column;
    align-items: center;

    &__title {
      max-width: 100%;
    }

    &__block {
      padding: 10px 0;
    }

    &__text {
      max-width: 400px;
      justify-content: space-around;
      padding-left: 30px;
    }

    &__svg-mob {
      display: block;
    }
    &__svg-desctop {
      display: none;
    }

    &__svg {
      width: 100px;
      height: 100px;
    }
  }
}

@media (max-width: $break-sm) {
  .info-block {
    padding: 30px 5% 0 5%;
    flex-direction: column;

    &__block {
      padding-bottom: 30px;
    }

    &__svg-mob {
      display: block;
    }
    &__svg-desctop {
      display: none;
    }

    &__title {
      font-size: 18px;
      line-height: 26px;
      max-width: 100%;
      width: 100%;
    }

    &__text {
      font-size: 14px;
      line-height: 18px;
      max-width: 75%;
    }

    &__svg {
      width: 80px;
      height: 80px;
    }
  }
}
</style>
