<template>
  <div class="top-slider">
    <Carousel
      :settings="settings"
      :wrapAround="true"
      :mouseDrag="false"
      :autoplay="100000"
    >
      <Slide
        v-for="(slide, i) in this.slides"
        :key="slide.id"
        :id="'slide' + i"
      >
        <img :src="slide" alt="slide" class="carousel__item" />
      </Slide>
      <template #addons>
        <Navigation />
      </template>
    </Carousel>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import { Carousel, Pagination, Slide, Navigation } from "vue3-carousel";
import "vue3-carousel/dist/carousel.css";

export default defineComponent({
  name: "TopSlider",
  data() {
    return {
      settings: {
        itemsToShow: 4.5,
        snapAlign: "center",
      },
      slides: [
        require("../../../assets/image/test1.png"),
        require("../../../assets/image/test2.png"),
        require("../../../assets/image/test3.png"),
        require("../../../assets/image/test1.png"),
        require("../../../assets/image/test2.png"),
        require("../../../assets/image/test3.png"),
        require("../../../assets/image/test1.png"),
        require("../../../assets/image/test2.png"),
        require("../../../assets/image/test3.png"),
        require("../../../assets/image/test1.png"),
        require("../../../assets/image/test2.png"),
        require("../../../assets/image/test3.png"),
        require("../../../assets/image/test1.png"),
        require("../../../assets/image/test2.png"),
        require("../../../assets/image/test3.png"),
        require("../../../assets/image/test1.png"),
        require("../../../assets/image/test2.png"),
        require("../../../assets/image/test3.png"),
        require("../../../assets/image/test1.png"),
        require("../../../assets/image/test2.png"),
        require("../../../assets/image/test3.png"),
        require("../../../assets/image/test1.png"),
        require("../../../assets/image/test2.png"),
        require("../../../assets/image/test3.png"),
        require("../../../assets/image/test1.png"),
        require("../../../assets/image/test2.png"),
        require("../../../assets/image/test3.png"),
        require("../../../assets/image/test1.png"),
        require("../../../assets/image/test2.png"),
        require("../../../assets/image/test3.png"),
        require("../../../assets/image/test1.png"),
        require("../../../assets/image/test2.png"),
        require("../../../assets/image/test3.png"),
      ],
    };
  },
  mounted() {},
  components: {
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  // props: {
  //   slides: {
  //     type: Array,
  //     default: [],
  //   },
  // },
});
</script>

<style lang="scss" scoped>
.top-slider {
  max-width: 610px;
}
::v-deep(.carousel__track) {
  transition: unset !important;
}
.carousel__slide > .carousel__item {
  transform: scale(0.9);
}
.carousel__slide.carousel__slide--active.carousel__slide--visible
  > .carousel__item {
  transition: 3s;
  transform: scale(1.05);
}
.carousel__slide--visible > .carousel__item {
  opacity: 1;
  transform: rotateY(0);
}
.carousel__slide--next > .carousel__item {
  transform: translate(-10px);
}
.carousel__slide--prev > .carousel__item {
  transform: translate(10px);
}
.carousel__slide.carousel__slide--next,
.carousel__slide.carousel__slide--prev {
}
.carousel__slide.carousel__slide--visible {
}
.carousel__track .carousel__slide.carousel__slide--visible:nth-child(1) {
  opacity: 1;
  left: 8%;
  z-index: 3;
  transform: scale(0.95);
}
.carousel__track
  .carousel__slide.carousel__slide--active.carousel__slide--visible:nth-child(1) {
  left: 0;
  z-index: 5;
  transform: scale(1);
}
.carousel__slide.carousel__slide--active.carousel__slide--visible {
  z-index: 5;
  opacity: 1;
  transition: opacity 0.5s;
}
.carousel__slide.carousel__slide--next {
  transform: scale(0.85);
  transition: opacity 0.5s;
  z-index: 2;
  right: 18%;
  opacity: 1;
}
.carousel__slide.carousel__slide--active.carousel__slide--visible
  + .carousel__slide {
  z-index: 4;
  right: 9%;
  transform: scale(0.95);
  transition: opacity 0.5s;
  opacity: 1;
}
.carousel__slide.carousel__slide--prev {
  transform: scale(0.85);
  transition: opacity 0.5s;
  left: 17%;
  opacity: 1;
}
.carousel__slide.carousel__slide--prev
  + .carousel__slide.carousel__slide--visible {
  transform: scale(0.95);
  transition: opacity 0.5s;
  left: 8%;
  opacity: 1;
}
.carousel__slide {
  opacity: 0;
}

.carousel__slide.carousel__slide--visible {
  opacity: 0;
}

::v-deep(.carousel__prev) {
  background: transparent !important;
  background-image: url("~@/assets/image/arrow-prev-little.png") !important;
  background-position: center;
  width: 65px !important;
  height: 65px !important;
  left: 6px;

  &:hover {
    cursor: pointer;
  }
}

::v-deep(.carousel__next) {
  background: transparent !important;
  background-image: url("~@/assets/image/arrow-next-little.png") !important;
  background-position: center;
  width: 65px !important;
  height: 65px !important;

  &:hover {
    cursor: pointer;
  }
}

@media (min-width: $break-xxlg) {
  .top-slider {
    max-width: 800px;
    ::v-deep(.carousel__item) {
      width: 550px;
    }
    ::v-deep(.carousel) {
      position: relative;
      bottom: 30px;
    }
    ::v-deep(.carousel__prev) {
      left: 45px;
    }

    ::v-deep(.carousel__next) {
      right: 45px;
    }
  }
}

@media (max-width: $break-xlg) {
  .top-slider {
    max-width: 400px;
    ::v-deep(.carousel__item) {
      width: 250px;
    }
    ::v-deep(.carousel) {
      position: relative;
      bottom: 30px;
    }
  }
}
@media (max-width: $break-md) {
  .top-slider {
    max-width: 400px;
    ::v-deep(.carousel__item) {
      width: 200px;
    }
  }
}

@media (max-width: $break-sm) {
  .top-slider {
    padding: 50px 0;
    ::v-deep(.carousel__next) {
      width: 45px;
      width: 95%;
    }

    ::v-deep(.carousel) {
      bottom: 0;
    }

    ::v-deep(.carousel__prev) {
      background: transparent !important;
      background-image: url("~@/assets/image/adaptive/arrow-prev.png") !important;
      background-position: center;
      width: 40px !important;
      height: 50px !important;
      left: 0;

      &:hover {
        cursor: pointer;
      }
    }

    ::v-deep(.carousel__next) {
      background: transparent !important;
      background-image: url("~@/assets/image/adaptive/arrow-next.png") !important;
      background-position: center;
      width: 40px !important;
      height: 50px !important;
      right: 10px;

      &:hover {
        cursor: pointer;
      }
    }

    ::v-deep(.carousel__viewport) {
      display: flex;
      flex-direction: column;
      justify-content: center;
    }

    .carousel__slide.carousel__slide--prev {
      left: 21%;
    }

    .carousel__slide.carousel__slide--prev
      + .carousel__slide.carousel__slide--visible {
      left: 10%;
    }
    .carousel__slide.carousel__slide--next {
      right: 21%;
    }
    .carousel__slide.carousel__slide--active.carousel__slide--visible
      + .carousel__slide {
      right: 11%;
    }
  }
}
</style>
