<template>
  <div class="time-block">
    <div class="time-block__title">TImeline</div>
    <div class="time-block__wrap">
      <div class="time-block__section">
        <ArrowMain
          class="time-block__arrow-mobile time-block__arrow-mobile-first"
        />
        <div class="time-block__section time-block__section-laptop">
          <div class="time-block__block">
            <div class="time-block__block-center">Private mint</div>
          </div>
        </div>
      </div>
      <ArrowMain />
      <div class="time-block__section">
        <div class="time-block__block">
          <div class="time-block__block-center">Public mint</div>
        </div>
      </div>
      <ArrowMain />
      <div class="time-block__section">
        <ArrowMain class="time-block__arrow-mobile" />
        <div class="time-block__section time-block__section-laptop">
          <div class="time-block__block">
            <div class="time-block__block-center">Staking</div>
          </div>
        </div>
      </div>
      <ArrowMain />
      <div class="time-block__section">
        <ArrowMain class="time-block__arrow-laptope" />
        <div class="time-block__block">
          <div class="time-block__block-center">Mini game</div>
        </div>
      </div>
      <ArrowMain />
      <div class="time-block__section">
        <ArrowMain class="time-block__arrow-mobile" />
        <div class="time-block__section time-block__section-laptop">
          <div class="time-block__block">
            <div class="time-block__block-center">p2e game</div>
          </div>
        </div>
      </div>
      <ArrowMain />
      <div class="time-block__section">
        <div class="time-block__block">
          <div class="time-block__block-center">Meta verse</div>
        </div>
      </div>
      <ArrowMain
        class="time-block__arrow-mobile time-block__arrow-mobile-first"
      />
    </div>
    <MainButton @click="goToDashboard()" class="time-block__button">
      {{ this.data.joinUs }}
    </MainButton>
  </div>
</template>

<script>
import { defineComponent } from "vue";
import ArrowMain from "@/assets/svg/arrow-main.svg?inline";
import MainButton from "@/components/atoms/buttons/MainButton.vue";

export default defineComponent({
  name: "TimeBlock",
  data() {
    return {
      data: {
        joinUs: "Join us",
      },
      token: null,
      signIn: null,
    };
  },
  components: {
    ArrowMain,
    MainButton,
  },
  mounted() {
    this.token = localStorage.getItem("phantom-token");
    this.signIn = localStorage.getItem("sign-in");
    if (this.token != null) {
      this.data.joinUs = "To dashboard";
    } else if (this.signIn == '"sign-in"' && this.token == null) {
      this.data.joinUs = "Sign in";
    }
  },
  methods: {
    goToDashboard() {
      if (this.token == null) {
        this.$router.push("/registration-phantom");
      } else if (this.token != null) {
        this.$router.push("/dashboard/mint");
        this.data.joinUs = "To dashboard";
      }
    },
  },
});
</script>

<style lang="scss" scoped>
.time-block {
  padding: 0 5%;

  &__title {
    @include MainSectiontitle;
    text-align: left;
    padding-bottom: 50px;
  }

  &__arrow-laptope {
    display: none;
  }

  &__block {
    width: 160px;
    height: 160px;
    background: linear-gradient(92.57deg, #a8a3d6 -5.02%, #8d8a8a 72.2%);
    box-shadow: inset 0px 5px 8px rgba(0, 0, 0, 0.2);
    border-radius: 100px;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__block-center {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 117px;
    height: 117px;
    border-radius: 100px;
    background: #ffffff;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.25);
    font-size: 20px;
    line-height: 22px;
    text-align: center;
    letter-spacing: 0.06em;
    text-transform: uppercase;
    font-style: italic;
    font-weight: 800;
  }

  &__section {
    display: flex;
    align-items: center;
  }

  &__wrap {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }

  &__button {
    width: 344px;
    margin: auto;
    margin-top: 60px;
  }

  &__arrow-mobile {
    display: none;
  }
}
@media (max-width: $break-xlg) {
  .time-block {
    &__wrap {
      flex-wrap: wrap;
      height: 360px;
    }
    &__arrow-laptope {
      display: block;
    }
    &__section {
      width: 25%;
    }
    &__section-laptop {
      width: 100%;
    }
  }
}
@media (max-width: $break-md) {
  .time-block {
    &__wrap {
      flex-wrap: wrap;
      height: 360px;
    }
    &__arrow-laptope {
      display: block;
    }
  }
}

@media (max-width: $break-sm) {
  .time-block {
    padding: 0 3%;
    &__wrap {
      flex-wrap: wrap;
      justify-content: space-between;
    }
    &__section {
      width: auto;
    }
    &__title {
      font-size: 30px;
      text-align: center;
      padding-bottom: 20px;
    }
    &__arrow-laptope {
      display: none;
    }
    &__block {
      width: 120px;
      height: 120px;
      margin-bottom: 20px;
    }
    &__block-center {
      font-size: 16px;
    }
    &__button {
      width: 260px;
      margin-top: 70px;
    }
    svg {
      margin-bottom: 20px;
    }
    &__block-center {
      width: 90px;
      height: 90px;
    }
    &__arrow-mobile {
      display: block;
    }
    &__arrow-mobile-first {
      opacity: 0;
    }
  }
}
</style>
